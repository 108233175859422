class HotspotDetailsDTO {
  id: number;
  name: string;
  description: string;
  address: {
    address_line_1: string;
    city: string;
    state: string;
    country: string;
  };
  host: {
    name: string;
    profile_image_url: string;
  };
  experience: string;
  start_datetime: Date;
  duration: number;
  end_datetime: Date;
  banner_image_url: string;
  capacity: number;
  validity: boolean;

  constructor(
    id: number,
    name: string,
    description: string,
    address: {
      address_line_1: string;
      city: string;
      state: string;
      country: string;
    },
    host: { name: string; profile_image_url: string },
    experience: { name: string },
    start_datetime: Date,
    duration: number,
    end_datetime: Date,
    banner_image_url: string,
    capacity: number,
    validity: boolean
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.address = address;
    this.host = host;
    this.experience = experience.name;
    this.start_datetime = start_datetime;
    this.duration = duration;
    this.end_datetime = end_datetime;
    this.banner_image_url = banner_image_url;
    this.capacity = capacity;
    this.validity = validity;
  }

  static fromJson(json: any): HotspotDetailsDTO {
    return new HotspotDetailsDTO(
      json.id,
      json.name,
      json.description,
      json.address,
      json.host,
      json.experience,
      json.start_datetime,
      json.duration,
      json.end_datetime,
      json.banner_image_url,
      json.capacity,
      json.validity
    );
  }
}

export { HotspotDetailsDTO };
