import verified from "@assets/verified.png";
import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Milestone } from "lucide-react";

import { HotspotDetailsDTO } from "../repository/entities";
import {
  getLongDay,
  getShortDate,
  getShortTime,
} from "../utils/date.time.utils";

import RequestInviteDrawer from "./RequestInviteDrawer";

const slideUpVariants = {
  hidden: { y: 100, opacity: 0 },
  visible: { y: -70, opacity: 1 },
  exit: { y: 100, opacity: 0 },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

interface HotspotDetailPageProps {
  isActive: boolean;
  hotspot: HotspotDetailsDTO;
}

const HotspotDetailPage: React.FC<HotspotDetailPageProps> = ({
  isActive,
  hotspot,
}) => {
  if (!isActive) {
    return null;
  }

  return (
    <>
      <motion.div
        variants={slideUpVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        <VStack p="1rem" spacing="1rem" mb="5rem">
          <HStack spacing="1rem" justifyContent="space-around" w="100%">
            <VStack
              background="b3"
              flex="1"
              height="6.5rem"
              borderRadius="0.5rem"
              alignItems="flex-start"
              pt="1rem"
              pl="1rem"
            >
              <Text color="#FFFFFF52" fontSize="10px">
                Hosted by
              </Text>
              <HStack spacing="1px">
                <Text color="white" fontSize="sm">
                  {hotspot.host.name}
                </Text>
                <Image src={verified} boxSize="12px" ml="2px" />
              </HStack>
            </VStack>
            <Image
              src={hotspot.host.profile_image_url}
              w="6.5rem"
              h="6.5rem"
              borderRadius="0.5rem"
              objectFit="cover"
            />
          </HStack>
          <HStack spacing="1rem" justifyContent="space-around" w="100%">
            <VStack
              w="50%"
              h="6.5rem"
              background="b3"
              borderRadius="0.5rem"
              flex="1"
              alignItems="flex-start"
              p="1rem"
              spacing="0.25rem"
            >
              <Text color="#FFFFFF52" fontSize="10px">
                Date
              </Text>
              <Text color="white" fontSize="sm" fontWeight="700" mt="0.25rem">
                {getShortDate(hotspot.start_datetime)}
              </Text>
              <Text color="#FFFFFF52" fontSize="sm">
                {getLongDay(hotspot.start_datetime)}
              </Text>
            </VStack>
            <VStack
              w="50%"
              h="6.5rem"
              background="b3"
              borderRadius="0.5rem"
              flex="1"
              alignItems="flex-start"
              p="1rem"
              spacing="0.25rem"
            >
              <Text color="#FFFFFF52" fontSize="10px">
                Time
              </Text>
              <Text color="white" fontSize="sm" fontWeight="700" mt="0.25rem">
                {getShortTime(hotspot.start_datetime)}
              </Text>
              <Text color="#FFFFFF52" fontSize="sm">
                Onwards
              </Text>
            </VStack>
          </HStack>
          <VStack
            w="100%"
            h="auto"
            background="b3"
            borderRadius="0.5rem"
            alignItems="flex-start"
            p="1rem"
            mt="0.25rem"
            position="relative"
          >
            <Text color="#FFFFFF52" fontSize="10px">
              Venue
            </Text>
            <Text color="white" fontSize="sm" mt="0.25rem">
              {hotspot.address.address_line_1}
            </Text>
            <Text color="#FFFFFF52" fontSize="sm">
              {hotspot.address.city}, {hotspot.address.state},{" "}
              {hotspot.address.country}
            </Text>
            <Box position="absolute" top="38%" right="1rem">
              <Milestone size="20px" color="white" />
            </Box>
          </VStack>
          <VStack
            w="100%"
            h="auto"
            background="b3"
            borderRadius="0.5rem"
            alignItems="flex-start"
            p="1rem"
            spacing="0.75rem"
          >
            <Text color="#FFFFFF52" fontSize="10px">
              About the Hotspot
            </Text>
            <Text color="#FFFFFFB8" whiteSpace="pre-line">
              {hotspot.description}
            </Text>
          </VStack>
        </VStack>
      </motion.div>
      <Box
        p="1rem"
        pb="2rem"
        backdropFilter="blur(16px)"
        borderTopRadius="0.5rem"
        background="#10101080"
        position="fixed"
        bottom={0}
        width="100vw"
      >
        <motion.div
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <RequestInviteDrawer hotspot={hotspot} />
        </motion.div>
      </Box>
    </>
  );
};

export default HotspotDetailPage;
